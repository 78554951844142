import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {format} from 'date-fns';
import {getGeneratedCsvReport} from "../../services/api";
import {useIntl} from "react-intl";
import {fi} from 'date-fns/locale';
import toastStore from "../../stores/toast-store";

interface DateDialogProps {
  open: boolean;
  onClose: () => void;
}

export const DatePickerDialog: React.FC<DateDialogProps> = ({
                                                 open,
                                                 onClose
                                               }) => {
  const intl = useIntl();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleOkClick = async () => {
    const formattedStartDate = startDate ? format(startDate, 'yyyy-MM-dd') : '';
    const formattedEndDate = endDate ? format(endDate, 'yyyy-MM-dd') : '';

    try {
      const fileName = generateFileName(formattedStartDate, formattedEndDate)
      const response = await getGeneratedCsvReport(formattedStartDate, formattedEndDate);

      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
        })
      );

      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      toastStore.setToast('DownloadReportSuccess', 'success');
    } catch (error) {
      toastStore.setToast('DownloadReportError', 'error');
      console.error('Error downloading the CSV:', error);
    }

    onClose();
  };

  const generateFileName = (startDate: string, endDate: string) => {
    const [startYear, startMonth, startDay] = startDate.split('-');
    const [endYear, endMonth, endDay] = endDate.split('-');

    const formattedStartDate = `${startDay}-${startMonth}-${startYear}`;
    const formattedEndDate = `${endDay}-${endMonth}-${endYear}`;

    return  `${formattedStartDate}_${formattedEndDate}_report.csv`
  }
  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      setStartDate(date);
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date){
      setEndDate(date);
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>
        {intl.formatMessage({ id: "SelectDates" })}
      </DialogTitle>
      <DialogContent>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fi}>
            <KeyboardDatePicker
              style={{ marginRight: 10 }}
              // variant={"inline"}
              fullWidth={false}
              disableToolbar={true}
              margin="normal"
              label={intl.formatMessage({ id: "StartDate" })}
              format="dd/MM/yyyy"
              value={startDate}
              onChange={handleStartDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />

            <KeyboardDatePicker
              style={{ marginLeft: 10 }}
              // variant={"inline"}
              fullWidth={false}
              disableToolbar={true}
              margin="normal"
              label={intl.formatMessage({ id: "EndDate" })}
              format="dd/MM/yyyy"
              value={endDate}
              onChange={handleEndDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
        </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="primary">
          {intl.formatMessage({ id: "Cancel" })}
        </Button>
        <Button onClick={handleOkClick} color="primary">
          {intl.formatMessage({ id: "Download" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
