import React, {FC, useState} from 'react';
import {RemovedFishReport} from './removed-fish/removed-fish-report';
import {Box, Button, Typography} from '@material-ui/core';
import {Accordion} from '../accordion';
import {useStore} from '../../stores/store-hooks';
import {ICompany} from '../../models/company';
import {FormattedMessage as M, useIntl} from 'react-intl';
import {useTheme} from "@emotion/react";
import {CloudDownload} from "@material-ui/icons";
import {DatePickerDialog} from "../common/date-selection-dialog";

interface IReportType {
  id: number;
  name: string;
  Component: FC<ICompany>;
}

const reportTypes: Array<IReportType> = [
  {
    id: 1,
    name: 'RemovedFishReportName',
    Component: RemovedFishReport,
  }
];

export const ReportsList: FC<ICompany> = (company) => {
  const intl = useIntl();
  const reportStore = useStore('reportStore');
  const theme = useTheme();
  const [dialogOpen, setDialogOpen] = useState(false);

  const onReportExpand = async (expanded: boolean) => {
    if (!expanded) return;
    reportStore.loadReportForCompany(company.id);
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = async () => {
    setDialogOpen(false);
  };


  return (
    <Box width="100%">
      {reportTypes.map((reportType) => {
        return (
          <Accordion
            onExpandCallback={(expanded) => onReportExpand(expanded)}
            key={reportType.id}
            id={reportType.id}
            expandIcon={true}
            isExpandedInitially={false}
            defaultBorder={theme.palette.line1.main}
            defaultBgColor={theme.palette.background2.main}
            accordionHeader={
              <Button fullWidth style={{justifyContent: "flex-start", height: "100%"}}>
                <Typography variant="h2">
                  <M id={reportType.name}/>
                </Typography>
              </Button>
            }
            accordionContent={<reportType.Component id={company.id}/>}
          />
        )
      })}

      <div style={{
        display: 'flex',
        padding: '0px 16px',
        flexDirection: 'row',
        alignItems: 'center',
        minHeight: '48px',
        transition: 'min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderRadius: '5px',
        backgroundColor: '#F8FAFB',
        border: '1px solid #B5B5B5',
        margin: '8px 8px 0 4px',
        boxSizing: 'inherit'
      }}>

        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          margin: '14px 2px 14px 14px'
        }}>
          <Typography variant="h2"
                      style={{display: 'flex'}}>
            <M id={intl.formatMessage({id: "DownloadReport"})}/>
          </Typography>

          <Button style={{marginLeft: 'auto', minWidth: '0', padding: '0', display: 'flex'}}
                  onClick={handleOpenDialog}>
            <CloudDownload/>
          </Button>
        </div>


        <DatePickerDialog
          open={dialogOpen}
          onClose={handleCloseDialog}
        />
      </div>
    </Box>
  );
};
